import { Hero } from "@/components/sections";

export { Page };

function Page() {
  return (
    <>
      <Hero
        title="Sorry, something went wrong. Page not found."
        subtitle=""
        actions={<></>}
      />
    </>
  );
}
